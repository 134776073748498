
import CamerasAutocomplete from "@/components/CamerasAutocomplete"
import Textarea from "@/components/Textarea"
import BimCompareContainer from "@/components/bimCompare/BimCompareContainer"
import { CameraFeatureFlag } from "@evercam/shared/types/camera"
import { mapStores } from "pinia"
import { useBimCompareStore } from "@/stores/bimCompare"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"

export default {
  meta: {
    pageId: AnalyticsEventPageId.EmbeddedBimCompare,
  },
  components: {
    BimCompareContainer,
    CamerasAutocomplete,
    Textarea,
  },
  async asyncData({ $moment }) {
    return {
      recordingDateTime: $moment().toISOString(),
    }
  },
  data() {
    return {
      selectedPreAuth: true,
      selectedMode: false,
      embedded: true,
      userApiId: "API_ID",
      userApiKey: "API_KEY",
      selectedCamera: null,
      rand: "",
      sidebarOption: "&sidebar=false",
      bottombarOption: "&bottombar=false",
      lockBottombar: false,
    }
  },
  head() {
    return {
      title: "Evercam | Recordings Widget",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useBimCompareStore, useCameraStore, useAccountStore),
    embedCode() {
      if (this.selectedCamera) {
        let preAuth = this.selectedPreAuth
          ? `&api_id=${this.userApiId}&api_key=${this.userApiKey}`
          : ""
        let isPrivate = this.selectedCamera.isPublic === false
        const selectedMode = `&dark_mode=${this.selectedMode}`
        const embedded = `&embedded=${this.embedded}`

        // eslint-disable-next-line no-useless-escape
        return `<div evercam="bim-compare"></div><script type="text/javascript" src="${window.location.origin}/bim.compare.widget.initiator.js?camera=${this.selectedCamera?.id}&private=${isPrivate}${preAuth}${this.sidebarOption}${this.bottombarOption}${selectedMode}${embedded}"><\/script>`
      }

      return ""
    },
    bimCompareCameras() {
      return this.filterCameras(this.cameraStore.cameras)
    },
    isPublicCamera() {
      return this.selectedCamera.isPublic
    },
    userHasBimCompareCameras() {
      return !!this.bimCompareCameras?.length
    },
    cameraId() {
      return `${(this.selectedCamera || this.defaultCamera)?.id}`
    },
    defaultCamera() {
      return this.bimCompareCameras?.[0]
    },
  },
  watch: {
    "bimCompareStore.isSidebarPreview"(val) {
      this.sidebarOption = `&sidebar=${val}`
    },
    "bimCompareStore.isBottombarPreview"(val) {
      this.bottombarOption = `&bottombar=${val}`
    },
    "bimCompareStore.has4D": {
      handler(value) {
        if (!value) {
          this.bimCompareStore.isBottombarPreview = false
          this.lockBottombar = true

          return
        }
        this.lockBottombar = false
      },
      immediate: true,
    },
    selectedCamera(camera) {
      if (!camera?.id) {
        return
      }
      this.cameraStore.selectCamera(camera.id)
      this.rand = Math.round(Math.random() * 1000)
      this.updatePreAuthStatus()
    },
  },
  created() {
    this.bimCompareStore.isWidgetPreview = true
    this.selectedCamera = this.defaultCamera
    this.cameraStore.selectCamera(this.selectedCamera?.id)
  },
  beforeDestroy() {
    this.bimCompareStore.isWidgetPreview = false
  },
  async mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)

    if (this.$permissions.user.is.admin()) {
      return
    }

    if (!this.accountStore.apiId || !this.accountStore.apiKey) {
      await this.accountStore.fetchUserCredentials()
    }
    this.updatePreAuthStatus()
    this.userApiId = this.accountStore.apiId
    this.userApiKey = this.accountStore.apiKey
  },
  methods: {
    updatePreAuthStatus() {
      this.selectedPreAuth = !this.isPublicCamera
    },
    filterCameras(cameras) {
      return cameras?.filter((camera) =>
        camera.featureFlags.includes(CameraFeatureFlag.BimCompare)
      )
    },
  },
}
